import React from 'react'
import './Modal.css'

const closeIcon = <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
</svg>

function Modal({setViewModal}) {
  return (
    <div className='modal'>
        <div className="modal_block">
            <h3>Ձեր հարցումը ուղարկվել է, քիչ անց մեր մասնագետը Ձեզ հետ կապ կհաստատի։</h3>
            <span className='close' onClick={() => setViewModal(false)}>{closeIcon}</span>
        </div>
    </div>
  )
}

export default Modal