
import { useState } from 'react';
import './App.css';
import Forma from './components/Forma/Forma';
import Modal from './components/Modal/Modal';
import NavBar from './components/NavBar/NavBar';


function App() {
  const [viewModal, setViewModal] = useState(false);
  return (
    <div className="App">
      <NavBar/>
      <Forma setViewModal={setViewModal}/>
      {viewModal && <Modal setViewModal={setViewModal}/>}
    </div>
  );
}

export default App;
