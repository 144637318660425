import React, { memo, useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from 'yup';
import './Forma.css';

function Forma({ setViewModal }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const getValidationSchema = () => {
    switch (step) {
      case 1:
        return yup.object().shape({
          name: yup.string().required('Պարտադիր դաշտ'),
          surname: yup.string().required('Պարտադիր դաշտ'),
        });
      case 2:
        return yup.object().shape({
          worke_type: yup.string().required('Պարտադիր դաշտ'),
        });
      case 3:
        return yup.object().shape({
          delayCount: yup.string().required('Պարտադիր դաշտ'),
        });
      case 4:
        return yup.object().shape({
          amount: yup.string().required('Պարտադիր դաշտ'),
        });
      case 5:
        return yup.object().shape({
          phone: yup
            .string()
            .matches(/^[0-9]+$/, 'Գրել միայն թիվ')
            .required('Պարտադիր դաշտ'),
        });
      default:
        return yup.object();
    }
  };

  const handleNextStep = async (e, values, setErrors, isValid, handleSubmit, validateForm, resetForm) => {
    e.preventDefault();
    handleSubmit();
  
    const formErrors = await validateForm(values);
  
    if (Object.keys(formErrors).length === 0 && isValid) {
      if (step === 5) {
        setLoading(true);
  
        setTimeout(async () => {
          const obj = {
            name: values.name,
            surname: values.surname,
            phone: '+374' + values.phone,
            delayCount:
              values.delayCount === '0'
                ? '0 Օր'
                : values.delayCount === '1'
                ? 'Մինջև 30 Օր'
                : '30 օրից ավել',
            amount: values.amount,
            worke_type: values.worke_type,
          };
  
          try {
            await axios.post('https://limitmail.trigger.ltd/send-email', obj);
            setViewModal(true);
            setStep(1);
            resetForm();
          } catch (error) {
            console.error('Error sending request:', error);
          } finally {
            setLoading(false);
          }
        }, 3000);
      } else {
        setStep((prevStep) => prevStep + 1);
      }
    } else {
      setErrors(formErrors);
    }
  };
  

  return (
    <div className="form_div">
      <Formik
        initialValues={{
          name: '',
          surname: '',
          phone: '',
          delayCount: '',
          amount: '',
          worke_type: '',
        }}
        validationSchema={getValidationSchema()}
        onSubmit={() => { }}
        validateOnBlur
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          setErrors,
          handleSubmit,
          validateForm,
          resetForm,
        }) => (
          <form
            className="feedBack"
            onSubmit={(e) => handleNextStep(e, values, setErrors, isValid, handleSubmit, validateForm, resetForm)}
          >
            <h3>Մուտք համակարգ</h3>

            {step === 1 && (
              <>
                <p>(Լրացնել հայատառ)</p>
                <div className="name-inp">
                  <p className="place">Անուն</p>
                  <input
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.name && errors.name && (
                    <p className="error_formik">{errors.name}</p>
                  )}
                </div>
                <div className="surname-inp">
                  <p className="place">Ազգանուն</p>
                  <input
                    type="text"
                    name="surname"
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.surname && errors.surname && (
                    <p className="error_formik">{errors.surname}</p>
                  )}
                </div>
              </>
            )}

            {step === 2 && (
              <div className="radio-inp">
                <p className="place">Գրանցված աշխատող եք թե՞ ոչ</p>
                <label>
                  <input
                    type="radio"
                    name="worke_type"
                    value="1"
                    checked={values.worke_type === '1'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Այո</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="worke_type"
                    value="0"
                    checked={values.worke_type === '0'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Ոչ</span>
                </label>
                {touched.worke_type && errors.worke_type && (
                  <p className="error_formik">{errors.worke_type}</p>
                )}
              </div>
            )}

            {step === 3 && (
              <div className="delayCount-inp">
                <p className="place">Ուշացումների քանակ (օր)</p>
                <div className='delay_labels_div'>
                  <label>
                    <input
                      type="radio"
                      name="delayCount"
                      value="0"
                      checked={values.delayCount === '0'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span>0 օր</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="delayCount"
                      value="1"
                      checked={values.delayCount === '1'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span>Մինջև 30 օր</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="delayCount"
                      value="2"
                      checked={values.delayCount === '2'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span>30 օրից ավել</span>
                  </label>
                </div>
                {touched.delayCount && errors.delayCount && (
                  <p className="error_formik">{errors.delayCount}</p>
                )}
              </div>
            )}

            {step === 4 && (
              <div className="amount-inp">
                <p className="place">Գումարի չափ (դրամ)</p>
                <input
                  type="text"
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.amount && errors.amount && (
                  <p className="error_formik">{errors.amount}</p>
                )}
              </div>
            )}

            {step === 5 && (
              <div className="phone-inp">
                <p className="place">Հեռախոսահամար</p>
                <div className="phone">
                  <span className="plus">+374</span>
                  <input
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.phone && errors.phone && (
                  <p className="error_formik">{errors.phone}</p>
                )}
              </div>
            )}

            <button className="feedBack_btn" type="submit" disabled={loading}>
              {loading ? 'Ուղարկվում է...' : step === 5 ? 'Ուղարկել' : 'Առաջ'}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default memo(Forma);
