import React, { memo } from 'react'
import './NavBar.css'
import logo from '../../images/LOGO LIMIT.png'
function NavBar() {
  return (
    <div className='nav_bar'>
        <div className="container">
            <img className='logo' src={logo} alt="" />
        </div>
    </div>
  )
}

export default memo(NavBar)